<template>
  <div :class="{ 'web-header': true, 'menu-open':menuShow, 'border-bottom': borderBottom }">
    <div class="web-header-wrap">
      <div class="web-header-wrap-logo" v-if="$store.getters.getPcLogo">
        <a href="/">
          <img :src="$store.getters.getPcLogo" :title="$store.getters.getWebsiteName" :alt="$store.getters.getWebsiteName" />
        </a>
      </div>
      <div :class="{ 'web-header-wrap-btn': true, 'active': menuShow }" @click="menuShow = !menuShow">
        <span class="line"></span>
        <span class="line"></span>
        <span class="line"></span>
        <i class="el-icon-close"></i>
      </div>
      <div :class="{ 'web-header-wrap-menu': true, 'open': menuShow }">
        <div class="web-header-wrap-menu-list">
          <div v-for="(item, index) in menuList" @click="jumpPage(item)" :class="{ 'web-header-wrap-menu-list-item': true, 'active': activeUrl === item.url }" :key="index">
            {{ item.title }}
          </div>
        </div>
        <div class="web-header-wrap-menu-search">
          <div class="web-header-wrap-menu-search-input">
            <input v-model="searchText" type="text" placeholder="请输入关键词..." @keyup.enter="searchToPage">
            <div class="web-header-wrap-menu-search-input-opera" @click="searchToPage">
              <i class="el-icon-search"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { navigationList, classifyList } from "@/api/navigation";
export default {
  name: "WebHeader",
  props: {
    // 当前菜单的地址
    activeUrl: {
      type: String,
      default: ""
    },
    // 是否显示下边框
    borderBottom: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchText: "",
      // 手机端是否展开菜单
      menuShow: false,
      menuList: []
    }
  },
  created() {
    this.getMenuList();
  },
  watch: {
    // 监听展开菜单
    menuShow(newVal) {
      if (newVal) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }
  },
  methods: {
    // 获取菜单列表
    async getMenuList() {
      let param = {
        pageSize: 20,
        currentPage: 1,
        orderBy: "sort",
        sortedBy: "asc",
        search: "parent_id:0",
        searchFields: "parent_id:="
      };
      let res = await navigationList(param);
      let classParam = {
        pageSize: 20,
        currentPage: 1,
        orderBy: "sort",
        sortedBy: "asc",
        search: "is_show:true",
        searchFields: "is_show:="
      };
      let classRes = await classifyList(classParam);
      if (res && res.code == 200 && classRes && classRes.code == 200) {
        this.menuListHandle(res.data.list, classRes.data.list)
      }
    },
    // 数据处理
    menuListHandle(navList, classList) {
      let arr = [];
      if (navList && navList.length > 0) {
        for (let i = 0; i < navList.length; i++) {
          let obj = {
            id: navList[i].id,
            title: navList[i].title,
            url: "/",
            target: navList[i].target,
          }
          if (navList[i].nav_type == '1') {
            // 关联分类
           let temp = classList.find(item => item.id === navList[i].page_id);
           if (temp.list_template) {
             obj.url = temp.list_template;
           }
          } else if (navList[i].nav_type == '2') {
            // 单页
            obj.url = `/detail/${navList[i].page_id}`
          } else if (navList[i].nav_type == '3') {
            // 外联
            obj.url = navList[i].link;
          }
          arr.push(obj);
        }
      }
      this.menuList = arr;
    },
    // 跳转到页面
    jumpPage(row) {
      this.routerLink(row.url, row.target);
    },
    // 搜索页面
    searchToPage() {
      if (this.searchText && this.searchText.trim()) {
        this.routerLink("/searchs/1/" + encodeURIComponent(this.searchText), true);
      } else {
        this.$message.warning("请输入关键词");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.web-header {
  width: 100%;
  min-width: 1270px;
  height: 130px;
  position: relative;
  background-color: #ffffff;
  &.border-bottom {
    border-bottom: 1px solid #e7e7e7;
  }
  &-wrap {
    position: relative;
    width: 1230px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-logo {
      width: 312px;
    }
    &-menu {
      flex: 1;
      margin-left: 68px;
      display: flex;
      justify-content: space-between;
      &-list {
        margin-top: 62px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-item {
          position: relative;
          cursor: pointer;
          font-size: 18px;
          font-weight: bold;
          padding: 0 21px 40px;
          display: block;
          &:hover {
            color: #004898 !important;
            &::after {
              content: "";
              width: 100%;
              height: 2px;
              background: #004898;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
          &.active {
            color: #004898 !important;
          }
        }
      }
      &-search {
        padding-top: 55px;
        &-input {
          width: 170px;
          height: 40px;
          line-height: 40px;
          border: 1px solid #aaa;
          border-radius: 999px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &-opera {
            display: block;
            width: 40px;
            height: 100%;
            cursor: pointer;
            i {
              font-weight: bolder;
              font-size: 16px;
            }
          }
          input {
            width: 100%;
            padding: 0 15px;
            font-size: 14px;
            outline: 0;
            border: 0;
            background: none;
            color: inherit;
          }
        }
      }
    }
    &-btn {
      display: none;
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .web-header {
    min-width: 320px;
    height: 149px;
    &.menu-open {
      position: fixed;
      top: 0;
      z-index: 999;
    }
    &-wrap {
      width: calc(100% - 60px);
      padding: 0 30px;
      &-logo {
        width: 300px !important;
      }
      &-menu {
        position: fixed;
        width: 100%;
        height: calc(100vh - 149px);
        left: 0;
        top: 149px;
        margin: 0;
        background-color: #ffffff;
        border-top: 1px solid #333;
        z-index: 99;
        display: none;
        &-list {
          position: absolute;
          width: 100%;
          top: 61px;
          margin: 0;
          display: inline-block;
          &-item {
            padding: 15px;
            border-bottom: 1px solid #DCDFE6;
            &:hover {
              &::after {
                height: 0px;
              }
            }
          }
        }
        &-search {
          position: absolute;
          top: 15px;
          width: 100%;
          padding: 0 0 5px 0;
          border-bottom: 1px solid #004898;
          &-input {
            margin: 0 auto;
            width: calc(100% - 30px);
            border: none;
            &-opera {
              text-align: center;
              i {
                font-weight: bolder;
                font-size: 20px;
              }
            }
            input {
              padding: 0;
              font-size: 18px;
              color: #004898;
            }
          }
        }
        &.open {
          display: block;
        }
      }
      &-btn {
        width: 80px;
        height: 45px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        i {
          font-size: 50px;
          font-weight: bolder;
          display: none;
        }
        .line {
          width: 50px;
          height: 4px;
          background-color: #333;
          display: inline-block;
        }
        &.active {
          i {
            display: inline-block;
          }
        }
      }
    }
  }
}

/* 针对手机 */
@media screen and (max-width: 767px) {
  .web-header {
    min-width: 320px;
    height: 77px;
    &.menu-open {
      position: fixed;
      top: 0;
      z-index: 999;
    }
    &-wrap {
      width: calc(100% - 60px);
      padding: 0 30px;
      &-logo {
        width: 156px !important;
      }
      &-menu {
        position: fixed;
        width: 100%;
        height: calc(100vh - 77px);
        left: 0;
        top: 77px;
        margin: 0;
        background-color: #ffffff;
        border-top: 1px solid #333;
        z-index: 99;
        display: none;
        &-list {
          position: absolute;
          width: 100%;
          top: 61px;
          margin: 0;
          display: inline-block;
          &-item {
            padding: 15px;
            border-bottom: 1px solid #DCDFE6;
            &:hover {
              &::after {
                height: 0px;
              }
            }
          }
        }
        &-search {
          position: absolute;
          top: 15px;
          width: 100%;
          padding: 0 0 5px 0;
          border-bottom: 1px solid #004898;
          &-input {
            margin: 0 auto;
            width: calc(100% - 30px);
            border: none;
            &-opera {
              text-align: center;
              i {
                font-weight: bolder;
                font-size: 20px;
              }
            }
            input {
              padding: 0;
              font-size: 18px;
              color: #004898;
            }
          }
        }
        &.open {
          display: block;
        }
      }
      &-btn {
        width: 41px;
        height: 26px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        i {
          font-size: 26px;
          font-weight: bolder;
          display: none;
        }
        .line {
          width: 26px;
          height: 4px;
          background-color: #333;
        }
        &.active {
          i {
            display: inline-block;
          }
        }
      }
    }
  }
}
</style>