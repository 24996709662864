<template>
  <div class="web-right-menu">
    <a class="web-right-menu-item" href="/" target="_blank">
      <div class="web-right-menu-item-wrap">
        <div class="web-right-menu-item-wrap-desc" style="padding-top: 18px;">
          <span>首页</span>
        </div>
      </div>
    </a>
    <a class="web-right-menu-item" href="/trends" target="_blank">
      <div class="web-right-menu-item-wrap">
        <div class="web-right-menu-item-wrap-desc" style="padding-top: 11px;">
          <span>NFI</span>
          <span>动态</span>
        </div>
      </div>
    </a>
    <a class="web-right-menu-item" href="/books" target="_blank">
      <div class="web-right-menu-item-wrap">
        <div class="web-right-menu-item-wrap-desc" style="padding-top: 18px;">
          <span>出版物</span>
        </div>
      </div>
    </a>
    <div class="web-right-menu-item" v-show="isShowTopBtn" @click="scrollToTop">
      <div class="web-right-menu-item-wrap">
        <img src="http://www.nfi.org.cn/Public/static/themes/nfi/image/temp/icon4_1.png" alt="" class="i1">
        <img src="http://www.nfi.org.cn/Public/static/themes/nfi/image/temp/icon5.png" alt="" class="i2">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WebRightMenu",
  data() {
    return {
      isShowTopBtn: false
    }
  },
  mounted() {
    // 页面加载完成后添加滚动监听
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    // 页面销毁前移除滚动监听
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      // 当页面滚动超过一定高度时显示按钮
      this.isShowTopBtn = window.pageYOffset > 400;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.web-right-menu {
  position: fixed;
  top: 35%;
  right: 20px;
  z-index: 100;
  transition-property: opacity, visibility, right;
  transition-duration: .5s;
  &-item {
    position: relative;
    display: block;
    padding: 10px;
    margin-top: 4px;
    border-radius: 999px;
    background-color: #fff;
    cursor: pointer;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    box-shadow: 0px 0px 18px 2px rgba(0, 0, 0, 0.1);
    transition-property: background;
    transition-duration: .5s;
    text-decoration: none;
    color: inherit;
    outline: none;
    &:first-child {
      margin-top: 0;
    }
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 45px;
      height: 45px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0px 0px 5.7px 0.3px rgba(0, 0, 0, 0.12);
      transition-property: background;
      transition-duration: .5s;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &-wrap {
      position: relative;
      width: 50px;
      height: 50px;
      line-height: 1;
      text-align: center;
      font-size: 0;
      letter-spacing: 0;
      transition-property: color;
      transition-duration: .3s;
      &::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        height: 100%;
      }
      &-desc {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &::after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          height: 100%;
        }
      }
      .i2 {
        display: none;
      }
    }
    &:hover {
      color: #ffffff;
      background-color: #abc5ee !important;
      &::before {
        background-color: #004898;
      }
      .i1 {
        display: none;
      }
      .i2 {
        display: block;
      }
    }
  }
}
</style>